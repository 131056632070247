<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="flex_wrap">
					<div class="intro_content maf001">
						<div class="intro_top character2">
							<div class="text_guide pt-20">
								<h4>{{ $language.greetings.greeting_01 }}</h4>
							</div>
							<div class="text_guide_desc pt-15">
<!--								<p>MAFIA는 모든 예술 분야를 아우르는<br/> 문화 마피아입니다.</p>
								<p>NFT 캐릭터 카드를 구입해 <br/> 카르텔(조직)을 생성하고 가입하여 <br/> 다양한 소통을 나눌 수 있습니다.</p>-->
                {{ $language.greetings.greeting_02 }}
							</div>
						</div>

					</div>
					<div class="intro_btn_wrap">
						<div class="btn_area">
							<button
								@click="$emit('to', { name: 'login'})"
								class="btn_l btn_fill_blue"
							>{{ $language.common.ok }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'mafia010'
	,data: function(){
		return {
			program: {
				name: this.$language.common.signUpComplete
				, not_header: true
				, not_footer: true
			}
		}
	}
	,methods: {
	}
	,created() {
		this.$emit('onLoad', this.program)
	}
}
</script>